<template>
  <div :class="$style.skeleton">
    <div :class="$style.banner" />
    <div :class="$style.box">
      <div v-for="(item, index) in 5" :key="index" :class="$style.item">
        <div :class="$style.round" />
        <div :class="$style.name" />
      </div>
    </div>
    <div :class="$style.title" />
    <div :class="$style.slice">
      <div :class="$style.left" />
      <div :class="$style.right" />
    </div>
    <div :class="$style.title" />
    <div :class="$style.content" />
  </div>
</template>

<script>
export default {
  name: 'skeleton',
  data() {
    return {}
  },
}
</script>

<style lang="less" module>
.skeleton {
  width: 350px;
  height: 600px;

  .banner {
    width: 350px;
    height: 150px;
    background: #fff;
  }

  .box {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .item {
      width: 50px;

      .round {
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 100%;
      }

      .name {
        width: 50px;
        height: 10px;
        margin-top: 10px;
        background: #fff;
      }
    }
  }

  .title {
    width: 80px;
    height: 20px;
    margin: 20px 0 10px;
    background: #fff;
  }

  .content {
    width: 350px;
    height: 120px;
    background: #fff;
  }

  .slice {
    display: flex;

    .left {
      width: 227px;
      height: 100px;
      background: #fff;
    }

    .right {
      width: 113px;
      height: 100px;
      margin-left: 10px;
      background: #fff;
    }
  }
}
</style>
