<template>
  <Draggable v-model="data.data.main_nav" ghost-class="ghost" handle=".mover" tag="tbody" @change="onChange">
    <slot />
  </Draggable>
  <!-- 拖拽icon class 命名mover -->
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  name: 'drag',
  components: {
    Draggable,
  },
  inject: ['data'],
  methods: {
    onChange() {
      this.data.save()
    },
  },
}
</script>
