<template>
  <div :class="$style.home">
    <!-- <a-affix :offset-top="-81"> -->
    <div :class="$style.left">
      <div :class="$style.head">
        <img :src="oss + '/manage/media/assets/statusbar_b.png'" alt="" />
      </div>
      <div :class="$style.content">
        <skeleton />
      </div>
      <div :class="$style.foot">
        <div :class="[$style.menu, data.nav_circular_corner ? $style.menuRadius : null]">
          <div :class="$style.drop">
            <span v-if="data.deputy_nav_status" />
          </div>
          <div :class="$style.menuBox">
            <div v-for="(item, index) in data.main_nav" :key="index" :class="$style.menuItem">
              <img v-if="index" :src="item.default_icon.cover" />
              <img v-else :src="item.select_icon.cover" />
              <p v-if="data.nav_char_status" :style="navTextColor(index)" class="ellipsis-width">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <img :class="$style.bar" :src="oss + '/manage/media/assets/homebar.png'" alt="" />
      </div>
    </div>
    <!-- </a-affix> -->
    <div :class="$style.right">
      <div :class="$style.color">
        <p :class="$style.title">前端主题</p>
        <div class="layout">
          <div class="label" :class="$style.stitle">配色方案</div>
          <div class="value" :class="$style.box">
            <div
              v-click-outside="pickOut"
              :style="{ 'background-color': color.hex8 }"
              :class="$style.colorBox"
              class="out"
              @click.self="onChooseColor()"
            >
              <i class="ri-arrow-down-s-line" @click.self="onChooseColor()" />
              <div :class="$style.pick">
                <chrome-picker v-if="visible.color" v-model="color" />
              </div>
              <span
                v-if="colorArr.indexOf(data.theme_color) === -1"
                :class="$style.colorBoxLin"
                :style="{ 'background-color': color.hex8 }"
              />
            </div>
            <div
              v-for="item in colorArr"
              :key="item"
              :style="{ 'background-color': item }"
              :class="[$style.colorBox, item === data.theme_color ? $style.colorBoxActive : null]"
              @click="onChoose(item)"
            >
              <span
                v-if="item === data.theme_color"
                :class="$style.colorBoxLine"
                :style="{ 'background-color': item }"
              />
            </div>
            <p :class="$style.colorTip">此配色应用在所有移动端界面，主题突出的颜色均为此颜色。</p>
          </div>
        </div>
      </div>

      <div v-if="data.main_nav.length" :class="$style.nav">
        <p :class="$style.title">前端导航</p>
        <a-table
          :columns="columns"
          :data-source="data.main_nav"
          :components="components"
          :pagination="false"
          row-key="id"
        >
          <template #mover="item">
            <img v-if="!item.fixed" :class="$style.mover" class="mover" :src="oss + '/manage/icon/default/sort.png'" />
          </template>
          <template #link="item">
            {{ item.link.label }}
          </template>
          <template #default="item">
            <div :class="$style.icon">
              <img :src="item.default_icon.cover" alt="" />
            </div>
          </template>
          <template #select="item" :class="$style.icon">
            <div :class="$style.icon">
              <img :src="item.select_icon.cover" alt="" />
            </div>
          </template>
          <!-- prettier-ignore -->
          <template #action="item, e, index">
            <i class="ri-edit-line" :class="$style.edit" @click="onEdit(item, index)" />
            <a-popconfirm v-if="!item.fixed" title="确定要删除吗？" @confirm="onDelete(item, index)">
              <i :class="!item.fixed ? $style.delete : $style.noDelete" class="ri-delete-bin-7-line" />
            </a-popconfirm>
            <i v-else :class="!item.fixed ? $style.delete : $style.noDelete" class="ri-delete-bin-7-line" />
          </template>
        </a-table>
        <BjButton :disabled="data.main_nav.length > 4" :class="$style.addBtn" type="dashed" @click="onAdd()">
          <i class="ri-add-line left" />
          添加导航
        </BjButton>
        <div :class="$style.line" />
        <div class="layout" :class="$style.text">
          <div class="label" :class="$style.stitle">导航文字</div>
          <div class="value line-40">
            <div>
              <BjSwitch v-model="data.nav_char_status" size="default" @change="save" />
            </div>
            <div v-if="data.nav_char_status" v-click-outside="pickerOutText" :class="$style.textBox">
              <div :class="$style.textBoxItem" :style="{ 'background-color': textColor.hex8 }">
                <i class="ri-arrow-down-s-line" @click="visible.textColor = !visible.textColor" />
                <chrome-picker v-if="visible.textColor" v-model="textColor" :class="$style.pick" />
              </div>
            </div>
            <span v-if="data.nav_char_status" :class="$style.tip">导航文字默认颜色</span>
            <div v-if="data.nav_char_status" v-click-outside="pickerOutSelect" :class="$style.textBox">
              <div :class="$style.textBoxItem" :style="{ 'background-color': selectColor.hex8 }">
                <i class="ri-arrow-down-s-line" @click="visible.selectColor = !visible.selectColor" />
                <chrome-picker v-if="visible.selectColor" v-model="selectColor" :class="$style.pick" />
              </div>
            </div>
            <span v-if="data.nav_char_status" :class="$style.tip">导航文字选中颜色</span>
            <p v-if="data.nav_char_status" class="tip mt-10">
              请设置导航文字的颜色，也可以点击
              <span :class="$style.primary" class="cursor" @click="defaultColor()">这里</span>
              一键设置颜色为主题色
            </p>
          </div>
        </div>
        <div class="mb-20 layout">
          <div class="label" :class="$style.stitle">导航圆角</div>
          <div class="value line-40">
            <BjSwitch v-model="data.nav_circular_corner" size="default" @change="save" />
          </div>
        </div>
        <div class="layout">
          <div class="label" :class="$style.stitle">副级导航</div>
          <div class="value line-40">
            <BjSwitch v-model="data.deputy_nav_status" size="default" @change="save" />
            <a-table
              v-if="data.deputy_nav_status"
              class="mt-10"
              :columns="viceColumns"
              :data-source="data.deputy_nav"
              :components="viceComponents"
              :pagination="false"
              row-key="id"
            >
              <template #mover>
                <img :class="$style.mover" class="mover" :src="oss + '/manage/icon/default/sort.png'" />
              </template>
              <template #link="item">
                {{ item.link.label }}
              </template>
              <template #icon="item" :class="$style.icon">
                <div :class="$style.icon">
                  <img :src="item.default_icon.cover" alt="" />
                </div>
              </template>
              <!-- prettier-ignore -->
              <template #action="item, e, index">
                <i class="ri-edit-line" :class="$style.edit" @click="onEditVice(item, index)" />
                <a-popconfirm title="确定要删除吗？" @confirm="onDeleteVice(item, index)">
                  <i :class="!item.fixed ? $style.delete : $style.noDelete" class="ri-delete-bin-7-line" />
                </a-popconfirm>
              </template>
            </a-table>
            <BjButton
              v-if="data.deputy_nav_status"
              :disabled="data.deputy_nav.length > 9"
              :class="$style.addBtn"
              type="dashed"
              @click="onAddVice()"
            >
              <i class="ri-add-line left" />
              添加导航
            </BjButton>
          </div>
        </div>
      </div>
    </div>
    <bj-modal
      :title="title"
      :visible="visible.nav"
      :class="$style.modal"
      :width="560"
      :body-style="{ height: '500px', 'overflow-y': 'auto' }"
      @ok="navOk"
      @cancel="navCancel"
    >
      <BjForm>
        <BjInput
          v-model="addNav.name"
          label="导航标题"
          v-bind="layout"
          tooltips
          label-align="left"
          rules="required|max:5"
          placeholder="请输入导航标题"
        />
        <a-row class="mt-10">
          <a-col :class="$style.title" span="6">
            <span class="required">*</span>
            默认图标
          </a-col>
          <a-col span="18">
            <!-- <BjButton class="btn-default" @click="onChooseImg('default_icon')">
              <i class="ri-upload-line left" />
              选择图片
            </BjButton>
            <div>
              <img v-if="addNav.default_icon.cover" :class="$style.imgbox" :src="addNav.default_icon.cover" alt="" />
            </div> -->
            <selectMaterial :data="addNav.default_icon" :allow="['image']" @change="defaultChange" />
            <p class="tip mt-10">图标建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
          </a-col>
        </a-row>
        <a-row class="mt-10">
          <a-col :class="$style.title" span="6">
            <span class="required">*</span>
            选中图标
          </a-col>
          <a-col span="18">
            <!-- <BjButton class="btn-default" @click="onChooseImg('select_icon')">
              <i class="ri-upload-line left" />
              选择图片
            </BjButton>
            <div>
              <img v-if="addNav.select_icon.cover" :class="$style.imgbox" :src="addNav.select_icon.cover" alt="" />
            </div> -->
            <selectMaterial :data="addNav.select_icon" :allow="['image']" @change="selectChange" />
            <p class="tip mt-10">图标建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
          </a-col>
        </a-row>
        <a-row v-if="!addNav.fixed" class="mt-10" :class="$style.title">
          <a-col span="6">
            <span class="required">*</span>
            跳转链接
          </a-col>
          <a-col span="18">
            <BjButton class="btn-default" @click="onChooseLink()">
              <i class="ri-link left" />
              设置链接
            </BjButton>
            <p v-if="addNav.link.label" class="mt-10">{{ addNav.link.label }}</p>
            <p class="tip mt-10">请选择点击导航菜单需要跳转的链接。</p>
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
    <bj-modal
      :title="title"
      :visible="visible.vice"
      :class="$style.modal"
      :width="560"
      :body-style="{ height: '500px', 'overflow-y': 'auto' }"
      z
      @ok="viceOk"
      @cancel="viceCancel"
    >
      <BjForm>
        <BjInput
          v-model="addNavVice.name"
          label="导航标题"
          v-bind="layout"
          label-align="left"
          tooltips
          rules="required|max:5"
          placeholder="请输入导航标题"
        />
      </BjForm>
      <a-row>
        <a-col span="6" :class="$style.title">
          <span class="required">*</span>
          导航图标
        </a-col>
        <a-col span="18">
          <!-- <BjButton class="btn-default" @click="onChooseImgVice()">
            <i class="ri-upload-line left" />
            选择图片
          </BjButton>
          <div>
            <img v-if="addNavVice.default_icon.cover" :class="$style.imgbox" :src="addNavVice.default_icon.cover" />
          </div> -->
          <selectMaterial :data="addNavVice.default_icon" :allow="['image']" @change="viceChange" />
          <p class="tip mt-10">图标建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</p>
        </a-col>
      </a-row>
      <a-row class="mt-10">
        <a-col span="6" :class="$style.title">
          <span class="required">*</span>
          跳转链接
        </a-col>
        <a-col span="18">
          <BjButton class="btn-default" @click="onChooseLinkVice()">
            <i class="ri-link left" />
            设置链接
          </BjButton>
          <p v-if="addNavVice.link.label" class="mt-10" :class="$style.title">{{ addNavVice.link.label }}</p>
          <p class="tip mt-10">请选择点击导航菜单需要跳转的链接。</p>
        </a-col>
      </a-row>
    </bj-modal>
    <chooseMaterial :visible.sync="visible.chooseImg" :allow="['image']" @getChoose="getChoose" />
    <chooseLink :visible.sync="visible.link" :data="addNav.link" @change="getLink" />
    <chooseLink :visible.sync="visible.linkVice" :data="addNavVice.link" @change="getLink" />
  </div>
</template>

<script>
import _ from 'lodash'
import { Chrome } from 'vue-color'

import chooseLink from '@/components/chooseLink'
import chooseMaterial from '@/components/chooseMaterial'
import selectMaterial from '@/components/selectMaterial'
import { terminalSettingService } from '@/service'
import { guid } from '@/utils'

import drag from './drag'
import skeleton from './skeleton.vue'
import vicedrag from './viceDrag'

const service = new terminalSettingService()

export default {
  name: 'Home',
  components: {
    'chrome-picker': Chrome,
    chooseMaterial,
    chooseLink,
    skeleton,
    selectMaterial,
  },
  provide() {
    return {
      data: this,
    }
  },
  data() {
    return {
      color: {
        hex8: '#1470DF',
      },
      colorArr: [
        '#1470DF',
        '#333333',
        '#0F5CFB',
        '#1EA5FA',
        '#8278F0',
        '#0ABB07',
        '#14BE82',
        '#FAD200',
        '#DCB978',
        '#FF9646',
        '#FA648C',
        '#FF2C2C',
      ],
      visible: {
        color: false,
        nav: false,
        vice: false,
        textColor: false,
        selectColor: false,
        chooseImg: false,
        link: false,
        linkVice: false,
      },
      textColor: {
        hex8: '#8c8c8c',
      },
      selectColor: {
        hex8: '#1470DF',
      },
      data: {
        main_nav: [],
      },
      components: {
        body: {
          wrapper: drag,
        },
      },
      viceComponents: {
        body: {
          wrapper: vicedrag,
        },
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      addNav: {
        name: null,
        default_icon: { id: null, type: 'image', cover: null },
        select_icon: { id: null, type: 'image', cover: null },
        link: {
          type: 'poc_index',
          params: {},
          name: null,
        },
      },
      addNavVice: {
        name: null,
        default_icon: { id: null, type: 'image', cover: null },
        link: {
          type: 'poc_index',
          params: {},
          name: null,
        },
      },
      isAdd: true,
      imgType: null, // 选择哪个图标
      editIndex: null,
      isAddVice: true,
      editIndexVice: null,
      chooseTypeMain: true, // 是否是主导航添加图
      title: '',
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 50,
          scopedSlots: {
            customRender: 'mover',
          },
        },
        { title: '导航名称', dataIndex: 'name', ellipsis: true },
        {
          title: '跳转链接',
          scopedSlots: {
            customRender: 'link',
          },
        },
        {
          title: '默认图标',
          scopedSlots: {
            customRender: 'default',
          },
        },
        {
          title: '选中图标',
          scopedSlots: {
            customRender: 'select',
          },
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
    viceColumns() {
      return [
        {
          title: '',
          width: 50,
          scopedSlots: {
            customRender: 'mover',
          },
        },
        { title: '导航名称', dataIndex: 'name', ellipsis: true },
        {
          title: '跳转链接',
          scopedSlots: {
            customRender: 'link',
          },
        },
        {
          title: '导航图标',
          scopedSlots: {
            customRender: 'icon',
          },
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  watch: {
    'color.hex8'(val) {
      if (val !== this.data.theme_color) {
        this.data.theme_color = val
        this.save()
      }
    },
    'textColor.hex8'(val) {
      if (val !== this.data.nav_char_default_color) {
        this.data.nav_char_default_color = val
        this.save(true)
      }
    },
    'selectColor.hex8'(val) {
      if (val !== this.data.nav_char_select_color) {
        this.data.nav_char_select_color = val
        this.save(true)
      }
    },
  },
  created() {
    this.getInfo()
  },
  methods: {
    navTextColor(index) {
      return {
        color: this.data.nav_char_status ? (index ? this.textColor.hex8 : this.selectColor.hex8) : '#000',
      }
    },
    async getInfo() {
      try {
        const { data } = await service.getInfoGlobal()
        this.data = data
        this.data.main_nav.map(item => {
          item.id = guid()
        })
        // if (this.colorArr.indexOf(data.theme_color) === -1) {
        this.color.hex8 = data.theme_color
        // }
        this.textColor.hex8 = data.nav_char_default_color
        this.selectColor.hex8 = data.nav_char_select_color
      } catch (e) {}
    },
    async save(noRefresh) {
      try {
        let params = {
          ...this.data,
          theme_color: this.color.hex8,
          main_nav: JSON.stringify(this.data.main_nav),
          deputy_nav: JSON.stringify(this.data.deputy_nav),
          nav_char_default_color: this.textColor.hex8,
          nav_char_select_color: this.selectColor.hex8,
        }
        await service.saveInfoGlobal(params)
        if (!noRefresh) {
          this.getInfo()
        }
      } catch (e) {
        this.getInfo()
      }
    },
    onChooseColor() {
      this.visible.color = !this.visible.color
    },
    onChoose(item) {
      this.data.theme_color = item
      this.color.hex8 = item
      this.save()
    },
    pickOut() {
      this.visible.color = false
    },
    onDelete(item, index) {
      if (item.fixed) return
      this.data.main_nav.splice(index, 1)
      this.save()
    },
    onDeleteVice(item, index) {
      this.data.deputy_nav.splice(index, 1)
      this.save()
    },
    onAdd() {
      this.visible.nav = true
      this.isAdd = true
      this.title = '添加导航'
      this.addNav = {
        name: null,
        default_icon: { id: null, type: 'image', cover: null },
        select_icon: { id: null, type: 'image', cover: null },
        link: {
          type: 'poc_index',
          params: {},
          label: null,
        },
      }
    },
    onEdit(item, index) {
      this.visible.nav = true
      this.isAdd = false
      this.title = '编辑导航'
      this.addNav = _.cloneDeep(item)
      this.editIndex = index
    },
    onAddVice() {
      if (this.data.deputy_nav.length > 9) {
        return
      }
      this.visible.vice = true
      this.isAddVice = true
      this.title = '添加副级导航'
      this.addNavVice = {
        name: null,
        default_icon: { id: null, type: 'image', cover: null },
        link: {
          type: 'poc_index',
          params: {},
          label: null,
        },
      }
    },
    onEditVice(item, index) {
      this.visible.vice = true
      this.isAddVice = false
      this.title = '编辑副级导航'
      this.addNavVice = _.cloneDeep(item)
      this.editIndexVice = index
    },
    // onChooseImg(_type) {
    //   this.chooseTypeMain = true
    //   this.visible.chooseImg = true
    //   this.imgType = _type
    // },
    // onChooseImgVice() {
    //   this.chooseTypeMain = false
    //   this.visible.chooseImg = true
    // },
    onChooseLink() {
      this.chooseTypeMain = true
      this.visible.link = true
    },
    onChooseLinkVice() {
      this.chooseTypeMain = false
      this.visible.linkVice = true
    },
    getChoose(data) {
      if (this.chooseTypeMain) {
        this.addNav[this.imgType] = data[0]
      } else {
        this.addNavVice.default_icon = data[0]
      }
    },
    getLink(data) {
      if (this.chooseTypeMain) {
        this.addNav.link = _.cloneDeep(data)
      } else {
        this.addNavVice.link = _.cloneDeep(data)
      }
    },
    pickerOutText() {
      this.visible.textColor = false
    },
    pickerOutSelect() {
      this.visible.selectColor = false
    },
    defaultColor() {
      this.textColor = {
        hex8: '#5c5c5c',
      }
      this.selectColor = {
        hex8: '#1470DF',
      }
      this.save()
    },
    navOk() {
      if (!this.addNav.name) {
        this.$message.error('请添加标题')
        return
      }
      if (!this.addNav.default_icon.cover) {
        this.$message.error('请选择图标')
        return
      }
      if (!this.addNav.link.label && !this.addNav.fixed) {
        this.$message.error('请选择跳转链接')
        return
      }
      if (this.isAdd) {
        this.data.main_nav.splice(this.data.main_nav.length - 1, 0, this.addNav)
      } else {
        this.data.main_nav.splice(this.editIndex, 1, this.addNav)
      }
      this.visible.nav = false
      this.save()
    },
    navCancel() {
      this.visible.nav = false
    },
    viceOk() {
      if (!this.addNavVice.name) {
        this.$message.error('请添加标题')
        return
      }
      if (!this.addNavVice.default_icon.cover) {
        this.$message.error('请选择图标')
        return
      }
      if (!this.addNavVice.link.label) {
        this.$message.error('请选择跳转链接')
        return
      }
      if (this.isAddVice) {
        this.data.deputy_nav.splice(this.data.deputy_nav.length - 1, 0, this.addNavVice)
      } else {
        this.data.deputy_nav.splice(this.editIndexVice, 1, this.addNavVice)
      }
      this.visible.vice = false
      this.save()
    },
    viceCancel() {
      this.visible.vice = false
    },
    defaultChange(data) {
      this.addNav.default_icon = data[0]
    },
    selectChange(data) {
      this.addNav.select_icon = data[0]
    },
    viceChange(data) {
      this.addNavVice.default_icon = data[0]
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;
  flex-direction: row;

  :global {
    .ant-table-placeholder {
      border-bottom: 1px solid #e8e8e8;
    }

    .ant-table-row:last-child {
      td {
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }

  .icon {
    img {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }
  }

  .left {
    position: relative;
    width: 390px;
    height: 750px;
    margin-right: 20px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

    .head {
      width: 100%;
      height: 44px;
      background: #fff;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      padding: 20px;
    }

    .foot {
      position: absolute;
      bottom: 0;

      .bar {
        background: #fff;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .menu {
        height: 70px;
        background: #fff;

        .drop {
          height: 25px;
          line-height: 10px;
          text-align: center;

          span {
            display: inline-block;
            width: 40px;
            height: 4px;
            background: #eee;
            border-radius: 2px;
          }
        }

        &-box {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
        }

        &-item {
          width: 70px;
          text-align: center;

          img {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }

          p {
            margin: 2px 0 0 0;
          }
        }
      }

      .menu-radius {
        border-radius: 20px 20px 0 0;
      }
    }
  }

  .right {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    .box {
      display: flex;
      flex-wrap: wrap;
    }

    .title {
      margin-bottom: 20px;
      color: #000;
      font-size: 16px;
    }

    .stitle {
      color: #000;
      font-size: 14px;
    }

    .color {
      padding: 20px;
      background: #fff;

      .color-box {
        width: 40px;
        height: 40px;
        margin: 10px 20px 10px 0;
        line-height: 40px;
        text-align: center;
        border-radius: 6px;
        cursor: pointer;

        i {
          color: #fff;
          font-size: 24px;
        }
      }

      .color-box-active {
        box-shadow: 0 5px 10px 0 rgba(20, 112, 223, 40);
      }

      .color-box-line {
        position: relative;
        top: 25px;
        display: inline-block;
        width: 90%;
        height: 4px;
        border-radius: 100px;
      }

      .color-box-lin {
        position: relative;
        top: -20px;
        display: inline-block;
        width: 90%;
        height: 4px;
        border-radius: 100px;
      }

      .pick {
        position: absolute;
        top: 55px;
        z-index: 999;
      }

      .color-tip {
        width: 100%;
        margin-top: 20px;
        color: #5c5c5c;
        font-size: 12px;
      }
    }

    .line {
      width: 100%;
      margin: 10px 0;
      border-top: 1px solid #eee;
    }

    .nav {
      margin-top: 20px;
      padding: 20px;
      background: #fff;
    }

    .text-box {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid #eee;

      .text-box-item {
        width: 20px;
        height: 20px;
        margin: 5px auto;
        line-height: 20px;
        text-align: center;

        i {
          color: #fff;
        }
      }
    }
  }

  .no-delete {
    color: #bbb;
    font-size: 18px;
  }

  .delete {
    color: #ff2d55;
    font-size: 18px;
  }

  .edit {
    margin-right: 10px;
    color: @primary-color;
    font-size: 18px;
  }

  .primary {
    color: @primary-color;
  }

  .add-btn {
    margin: 10px 0 20px;
  }

  .text {
    margin-top: 10px;
    /* stylelint-disable-next-line no-descending-specificity */
    .pick {
      position: absolute;
      z-index: 999;
    }

    .tip {
      display: inline-block;
      margin: 0 10px;
      color: #000;
      font-size: 14px;
    }
  }
}

.imgbox {
  width: 24px;
  height: 24px;
  margin: 10px 0;
  object-fit: cover;
}

.modal {
  .title {
    color: #000;
    font-size: 14px;
  }
}

.mover {
  width: 20px;
  height: 20px;
  display: none;
}

.mover:active {
  cursor: grabbing;
}
</style>
